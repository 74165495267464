import './Header.css';

export default function Header() {
  return (
    <header>
      <div className="header-section">
        <h1>SKillsProject.ai</h1>
        <span className="title">
          Live Interview
        </span>
      </div>
    </header>
  );
}
